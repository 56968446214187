<template>
  <app-collapse-item
    v-if="protectGetThreads && router.currentRoute.params.threadId"
    :ref="`${uuid}-threadItem`"
    class="card-body:p-0"
    :is-visible="!loading"
  >
    <template #header>
      <div
        class="d-flex align-items-center"
        style="gap: .34rem"
      >
        <sw-icon
          icon="FilterIcon"
          style="margin-top: -.18rem"
        />

        <h6 class="mb-0">
          {{ $t('Threads') }}
        </h6>

        <b-spinner
          v-if="loading"
          type="grow"
          small
          class="mx-auto"
          variant="primary"
        />

        <feather-icon
          v-if="
            contact
              && contact.contactThreadAssignedContacts
              && contact.contactThreadAssignedContacts.length
          "
          v-b-tooltip.hover.v-primary.top
          :title="$t('ThisContactIsAssigned')"
          icon="AlertCircleIcon"
          class="text-primary cursor-pointer"
        />
      </div>
    </template>

    <b-list-group
      v-if="!loading"
      class="p-0"
      :class="{ 'pb-50': !protectAddThread }"
      flush
    >
      <b-list-group-item
        v-for="(threadItem, i) in threadList"
        :key="threadItem.id"
        class="d-flex flex-column modernActiveListGroupItem"
        :active="threadItem.id === thread.id"
        :class="{
          'success': threadItem.contactThreadFunnelProcessSelected && getThreadStatus(threadItem.contactThreadFunnelProcessSelected.status) && getThreadStatus(threadItem.contactThreadFunnelProcessSelected.status).status === 'COMPLETED',
          'danger': threadItem.contactThreadFunnelProcessSelected && getThreadStatus(threadItem.contactThreadFunnelProcessSelected.status) && getThreadStatus(threadItem.contactThreadFunnelProcessSelected.status).status !== 'COMPLETED',
          'cursor-pointer': threadItem.id !== thread.id
        }"
        style="row-gap: .34rem"
        @click="threadItem.id !== thread.id && changeActiveThread(threadItem); scrollContainerToTop()"
      >
        <!--    START::Header    -->
        <div
          class="d-flex flex-row"
          style="column-gap: .34rem"
        >
          <!--    START::Title    -->
          <div
            class="d-flex flex-column flex-wrap"
            style="row-gap: .2rem;"
          >
            <div
              class="d-flex flex-row align-items-center"
              style="gap: .34rem"
            >
              <!--    START::Is Primary    -->
              <sw-icon
                v-if="threadItem.isPrimary"
                v-b-tooltip.hover.v-primary
                icon="fa-heart"
                class="text-danger"
                :title="$t('PrimaryThread')"
              />
              <!--    END::Is Primary    -->

              <span
                v-b-tooltip.hover.right.v-dark
                class="mb-0 cursor-pointer h5 mb-0"
                :class="{
                  'cursor-default': threadItem.id === thread.id,
                }"
                :title="`${$t('Thread')}: ${threadItem.name}`"
              >
                {{ threadItem.name | truncate(24) }}
              </span>
            </div>

            <div
              v-if="threadItem.contactThreadFunnelProcessSelected"
              class="d-flex flex-row align-items-center"
              style="gap: .34rem"
            >
              <b-button-group
                v-if="protectCloseThread && !getThreadStatus(threadItem.contactThreadFunnelProcessSelected.status)"
                size="sm"
              >
                <b-button
                  v-b-tooltip.hover.v-success
                  variant="outline-success"
                  :title="$t('FinishAsSuccess')"
                  class="btn-icon p-0"
                  @click.prevent.stop="mCloseNoteModal('POSITIVE', threadItem, i)"
                >
                  <sw-icon icon="CheckIcon" />
                </b-button>

                <b-button
                  v-b-tooltip.hover.v-danger
                  variant="outline-danger"
                  :title="$t('FinishAsFailure')"
                  class="btn-icon p-0"
                  @click.prevent.stop="mCloseNoteModal('REJECTED', threadItem, i)"
                >
                  <sw-icon icon="XIcon" />
                </b-button>
              </b-button-group>

              <!--    START::Is Closed    -->
              <sw-icon
                v-else-if="threadItem.contactThreadFunnelProcessSelected && getThreadStatus(threadItem.contactThreadFunnelProcessSelected.status)"
                v-b-tooltip.hover="{ variant: getThreadStatus(threadItem.contactThreadFunnelProcessSelected.status).status === 'COMPLETED' ? 'success' : 'danger' }"
                :icon="getThreadStatus(threadItem.contactThreadFunnelProcessSelected.status).status === 'COMPLETED' ? 'CheckIcon' : 'XIcon'"
                style="margin-top: -.14rem"
                :class="{
                  'text-success': getThreadStatus(threadItem.contactThreadFunnelProcessSelected.status).status === 'COMPLETED',
                  'text-danger': getThreadStatus(threadItem.contactThreadFunnelProcessSelected.status).status === 'REJECTED'
                }"
                :title="$t(`funnel.status.${getThreadStatus(threadItem.contactThreadFunnelProcessSelected.status).status}`)"
              />
              <!--    END::Is Closed    -->

              <span
                v-b-tooltip.hover.right.v-dark
                class="h6 font-weight-normal mb-0"
                :title="`${$t('SalesFunnel')}: ${threadItem.contactThreadFunnelProcessSelected.contactThreadFunnelTypePoint.contactThreadFunnelType.name}`"
              >
                {{ threadItem.contactThreadFunnelProcessSelected.contactThreadFunnelTypePoint.contactThreadFunnelType.name | truncate(24) }}
              </span>
            </div>
          </div>
          <!--    END::Title    -->
        </div>
        <!--    END::Header    -->

        <!--    START::Progress    -->
        <b-row
          v-if="false"
          no-gutters
          style="row-gap: .34rem"
        >
          <b-col
            v-for="({ id, contactThreadFunnelTypePoint }) in threadItem.contactThreadFunnelProcesses"
            :key="id"
            v-b-tooltip.hover.v-primary
            :cols="progressCols(threadItem.contactThreadFunnelProcesses.length)"
            :title="`${contactThreadFunnelTypePoint.name} | ${contactThreadFunnelTypePoint.progressPercent}%`"
            class="px-25"
          >
            <div
              class="rounded"
              style="height: .5rem;"
              :style="`background: var(--${progressColor(contactThreadFunnelTypePoint.progressPercent)})`"
            />
          </b-col>
        </b-row>
        <!--    END::Progress    -->

        <!--    START::Footer    -->
        <div
          class="d-flex align-items-start justify-content-between"
          style="gap: .34rem"
        >
          <!--    START::Start side    -->
          <div
            class="d-flex align-items-center"
            style="column-gap: .5rem"
          >
            <!--    START::Created At    -->
            <sw-icon
              :id="`ttCreatedAt-${threadItem.id}${i}`"
              icon="CalendarIcon"
              style="margin-top: -.14rem"
              data-html="true"
            />

            <b-tooltip
              :target="`ttCreatedAt-${threadItem.id}${i}`"
              placement="bottom"
              variant="dark"
              triggers="hover"
            >
              {{ $t('ProcessCreatedAt') }}:
              <br>
              {{ threadItem.createdAt.date.split('.')[0] }}
            </b-tooltip>
            <!--    END::Created At    -->

            <!--    START::Value    -->
            <template v-if="thread.predictedValue">
              <sw-icon
                :id="`ttPredictedValue-${threadItem.id}${i}`"
                icon="DollarSignIcon"
                style="margin-top: -.14rem"
                class="text-success"
              />

              <b-tooltip
                :target="`ttPredictedValue-${threadItem.id}${i}`"
                placement="bottom"
                variant="dark"
                triggers="hover"
              >
                {{ $t('ThreadValue') }}:
                <br>
                {{ threadItem.predictedValue || 0 | priceFormat }}
                {{ threadItem.predictedValueCurrency || 'PLN' }}
              </b-tooltip>
            </template>
            <!--    END::Value    -->

            <!--    START::Thread Above Norm    -->
            <template v-if="threadItem.contactThreadFunnelTypePointDelayed">
              <sw-icon
                :id="`ttThreadAboveNorm-${threadItem.id}${i}`"
                icon="AlertCircleIcon"
                style="margin-top: -.14rem"
                class="text-warning"
              />

              <b-tooltip
                :target="`ttThreadAboveNorm-${threadItem.id}${i}`"
                placement="bottom"
                variant="dark"
                triggers="hover"
              >
                {{ $t('funnel.ThreadAboveNorm') }}:
                <br>
                {{ threadItem.contactThreadFunnelTypePointDelayedAt }}
              </b-tooltip>
            </template>
            <!--    END::Thread Above Norm    -->

            <!--    START::Contact Above Norm    -->
            <template v-if="threadItem.contact.statusDelayed">
              <sw-icon
                :id="`ttContactAboveNorm-${threadItem.id}${i}`"
                icon="AlertTriangleIcon"
                style="margin-top: -.14rem"
                class="text-warning"
              />

              <b-tooltip
                v-if="contact.statusUpdatedAt && contact.statusUpdatedAt.date"
                :target="`ttContactAboveNorm-${threadItem.id}${i}`"
                placement="bottom"
                variant="dark"
                triggers="hover"
              >
                {{ $t('funnel.ContactAboveNorm') }}:
                <br>
                {{ contact.statusUpdatedAt.date.split('.')[0] }}
              </b-tooltip>
            </template>
            <!--    END::Contact Above Norm    -->

            <!--    START::Actions    -->
            <b-dropdown
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-tooltip.hover.v-primary
              no-caret
              dropup
              variant="flat"
              size="sm"
              toggle-class="p-0"
              :title="$t('Actions')"
            >
              <template #button-content>
                <b-button
                  variant="flat-primary"
                  size="sm"
                  class="btn-icon p-0"
                >
                  <sw-icon
                    icon="MoreHorizontalIcon"
                    size="16rem"
                  />
                </b-button>
              </template>

              <template v-if="protectCloseThread && threadItem.contactThreadFunnelProcessSelected && !getThreadStatus(threadItem.contactThreadFunnelProcessSelected.status)">
                <b-dropdown-item
                  variant="success"
                  @click.prevent.stop="mCloseNoteModal('CLOSED_POSITIVE', threadItem, i)"
                >
                  <dropdown-item-label
                    icon="CheckIcon"
                    :label="$t('FinishAsSuccess')"
                  />
                </b-dropdown-item>

                <b-dropdown-item
                  variant="danger"
                  @click.prevent.stop="mCloseNoteModal('CLOSED_NEGATIVE', threadItem, i)"
                >
                  <dropdown-item-label
                    icon="XIcon"
                    :label="$t('FinishAsFailure')"
                  />
                </b-dropdown-item>

                <b-dropdown-divider />
              </template>

              <b-dropdown-item
                v-if="protectSetPrimaryThread(threadItem)"
                @click.prevent.stop="switchThreadPrimaryStatus(threadItem)"
              >
                <sw-icon
                  icon="fa-heart"
                  :class="{
                    'text-danger': threadItem.isPrimary,
                    'text-secondary': !threadItem.isPrimary
                  }"
                  class="mr-25"
                />

                {{ $t(threadItem.isPrimary ? 'MarkAsNotBasic' : 'MarkAsBasic') }}
              </b-dropdown-item>

              <b-dropdown-item
                v-if="threadItem.mailConversationFirst"
                @click.prevent.stop="copy(threadItem.mailConversationFirst.fromMail)"
              >
                <dropdown-item-label
                  icon="CopyIcon"
                  :label="$t('CopyThreadEmail')"
                />
              </b-dropdown-item>

              <b-dropdown-divider v-if="protectEditThread(threadItem) || protectRemoveThread" />

              <b-dropdown-item
                v-if="protectEditThread(threadItem)"
                variant="primary"
                @click.prevent.stop="mEditThread(threadItem)"
              >
                <dropdown-item-label
                  icon="EditIcon"
                  :label="$t('edit')"
                />
              </b-dropdown-item>

              <b-dropdown-item
                v-if="protectRemoveThread"
                variant="danger"
                :disabled="threadItem.id === thread.id"
                @click.prevent.stop="removeThreadItem(threadItem)"
              >
                <dropdown-item-label
                  icon="TrashIcon"
                  :label="$t('Delete')"
                />
              </b-dropdown-item>
            </b-dropdown>
            <!--    END::Actions    -->
          </div>
          <!--    END::Start side    -->

          <!--    START::End side    -->
          <div
            class="d-flex align-items-center"
            style="column-gap: .64rem"
          >
            <!--    START::Assigned Users    -->
            <avatar-group
              v-if="protectGetUsers && threadItem.assignedUsers.length"
              :items="threadItem.assignedUsers"
              :max="3"
              :to="{ name: 'user' }"
              tip="AssignedUsers"
              size="20"
              force-redirect
              show-more
            />
            <!--    END::Assigned Users    -->

            <!--    START::Assigned Contacts    -->
            <avatar-group
              v-if="protectGetAssignedContacts && threadItem.contactThreadAssignedContacts.map(({ contact }) => ({ ...contact, threadId: threadItem.id })).length"
              :items="threadItem.contactThreadAssignedContacts.map(({ contact }) => ({ ...contact, threadId: threadItem.id }))"
              :max="3"
              :to="{ name: 'contact' }"
              tip="AssignedContact"
              size="20"
              force-redirect
              :show-tooltip="false"
              show-more
            />
            <!--    END::Assigned Contacts    -->

            <!--    START::Primary User    -->
            <avatar-group
              v-if="protectGetUsers"
              :items="[threadItem.assignedLeadUser]"
              :to="{ name: 'user' }"
              tip="PrimaryUser"
              size="20"
              force-redirect
            />
            <!--    END::Primary User    -->

            <!--    START::Thread Tasks    -->
            <b-button
              :id="`user_arrears_${threadItem.id}`"
              :ref="`user_arrears_${threadItem.id}`"
              :variant="threadItem.contactThreadTasksOpened && threadItem.contactThreadTasksOpened.length ? 'danger' : 'flat-danger'"
              class="btn-icon rounded-circle"
              style="padding: 5px"
            >
              <sw-icon icon="ListIcon" />
            </b-button>
            <user-arrears-popover
              :target="threadItem"
              container="funnel"
            />
            <!--    END::Thread Tasks    -->
          </div>
          <!--    END::End side    -->
        </div>
        <!--    END::Footer    -->
      </b-list-group-item>
    </b-list-group>

    <b-button
      v-if="protectAddThread"
      variant="flat-primary"
      class="w-100 py-1"
      @click.prevent.stop="mAddThread"
    >
      <sw-icon icon="PlusIcon" />

      {{ $t('AddThread') }}
    </b-button>

    <b-modal
      v-model="showCloseNoteModal"
      hide-footer
      :title="$t('FinishAs') + ' ' + (closeNote.status !== 'REJECTED' ? $t('funnel.status.Success') : $t('funnel.status.Failure'))"
      no-close-on-backdrop
      @hide="closeNote.reason = emptyCloseNote.reason; closeNote.note = emptyCloseNote.note; closeNote.status = emptyCloseNote.status; $forceUpdate()"
    >
      <!-- Edited name  -->
      <sw-select :name="$t('CloseReason')">
        <v-select
          v-model="closeNote.reason"
          :options="closeNote.status !== 'REJECTED' ? getPositive : getNegative"
          label="name"
          :reduce="reason => reason.id"
        >
          <template #no-options>
            {{ $t('NoOptions') }}
          </template>
        </v-select>
      </sw-select>
      <b-form-group :label="$t('note.Description')">
        <b-form-textarea
          v-model="closeNote.note"
          rows="5"
        />
      </b-form-group>
      <b-button
        variant="success"
        :disabled="!closeNote.reason || !closeNote.note"
        @click.prevent.stop="closeThreadFunnelProcess()"
      >
        {{ $t('Finish') }}
      </b-button>
    </b-modal>
  </app-collapse-item>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  BModal, BButtonGroup, VBTooltip, BTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  CLOSE_CONTACT_THREAD_FUNNEL_PROCESS,
  GET_CONTACT_THREAD_CLOSE_STATUS_REASONS, GET_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS,
  GET_CURRENT_CONTACT_THREAD_LIST, GET_THREAD_LIST_ITEM,
  OPEN_MODAL,
  REMOVE_CURRENT_CONTACT_THREAD,
  SWITCH_PRIMARY_THREAD, GET_THREAD_TASKS,
} from '@/@constants/mutations'
import { priceFormat } from '@core/filters/parts/valueFormat'
import UserArrearsPopover from '@/views/components/userArrears/UserArrearsPopover.vue'
import vSelect from 'vue-select'
import EventBus from '@/event-bus'
import {
  eChangeFunnelProcess,
  eChangeFunnelProcessStatus, eChangeThreadPrimaryStatus, eEditThread, eNewThreadTask, eNewThread, eRemoveThread,
} from '@/@types/events'
import router from '@/router'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    BModal, BButtonGroup, UserArrearsPopover, vSelect, BTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data: () => ({
    router,
    uuid: uuidv4(),

    loading: false,
    threadList: [],
    threadCloseStatusReasons: [],

    showCloseNoteModal: false,
    closeNoteThread: null,
    closeNote: {
      status: 'CLOSED_POSITIVE',
      reason: '',
      note: '',
    },
    emptyCloseNote: {
      status: 'CLOSED_POSITIVE',
      reason: '',
      note: '',
    },
  }),
  computed: {
    ...mapGetters({
      user: 'auth/getCurrentUser',

      contact: 'contact/contact',
      thread: 'contact/thread',
    }),

    protectGetThreads() {
      return this.checkRequiredPermissions([this.$roles.CONTACT_THREAD_VIEW_ALL, this.$roles.CONTACT_THREAD_VIEW_ASSIGNED, this.$roles.CONTACT_THREAD_VIEW_STRUCTURE, this.$roles.CONTACT_THREAD_VIEW_STRUCTURE_FIRST])
    },
    protectGetAssignedContacts() {
      return this.checkRequiredPermissions([this.$roles.CONTACT_VIEW_ALL, this.$roles.CONTACT_VIEW_ASSIGNED, this.$roles.CONTACT_VIEW_STRUCTURE, this.$roles.CONTACT_VIEW_STRUCTURE_FIRST])
    },
    protectGetUsers() {
      return this.checkRequiredPermissions([this.$roles.CONTACT_VIEW_ALL, this.$roles.CONTACT_VIEW_ASSIGNED, this.$roles.CONTACT_VIEW_STRUCTURE, this.$roles.CONTACT_VIEW_STRUCTURE_FIRST])
    },
    protectAddThread() {
      return this.checkRequiredPermissions([this.$roles.CONTACT_THREAD_ADD])
    },
    protectRemoveThread() {
      return this.checkRequiredPermissions([this.$roles.CONTACT_THREAD_DELETE])
    },
    protectCloseThread() {
      return this.checkRequiredViewPermissions([this.$viewRanks.SHOW_CONTACT_END_STATUS])
    },
    getNegative() {
      return this.threadCloseStatusReasons.map((a, index) => ({ ...a, index })).filter(t => t.status === 'CLOSED_NEGATIVE')
    },
    getPositive() {
      return this.threadCloseStatusReasons.map((a, index) => ({ ...a, index })).filter(t => t.status === 'CLOSED_POSITIVE')
    },
  },
  watch: {
    async contact() {
      if (this.protectGetThreads) await this.loadThreadList()
    },
  },
  async mounted() {
    if (this.protectGetThreads) {
      if (this.contact) await this.loadThreadList()

      await this.loadThreadCloseStatusReasons()

      EventBus.$on(eChangeFunnelProcess, this.handleChangeFunnelProcess)
      EventBus.$on(eChangeFunnelProcessStatus, this.handleChangeFunnelProcessStatus)
      EventBus.$on(eNewThread, this.handleNewThread)
      EventBus.$on(eEditThread, this.handleEditThread)
      EventBus.$on(eRemoveThread, this.handleRemoveThread)
      EventBus.$on(eChangeThreadPrimaryStatus, this.handleChangeThreadPrimaryStatus)
      EventBus.$on(eNewThreadTask, this.handleNewThreadTask)
    }
  },
  destroyed() {
    if (this.protectGetThreads) {
      EventBus.$off(eChangeFunnelProcess, this.handleChangeFunnelProcess)
      EventBus.$off(eChangeFunnelProcessStatus, this.handleChangeFunnelProcessStatus)
      EventBus.$off(eNewThread, this.handleNewThread)
      EventBus.$off(eEditThread, this.handleEditThread)
      EventBus.$off(eRemoveThread, this.handleRemoveThread)
      EventBus.$off(eChangeThreadPrimaryStatus, this.handleChangeThreadPrimaryStatus)
      EventBus.$off(eNewThreadTask, this.handleNewThreadTask)
    }
  },
  methods: {
    priceFormat,
    ...mapActions({
      getThread: `contact/${GET_THREAD_LIST_ITEM}`,
      getThreadList: `contact/${GET_CURRENT_CONTACT_THREAD_LIST}`,
      getThreadFunnelProcess: `contact/${GET_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS}`,
      switchThreadPrimary: `contact/${SWITCH_PRIMARY_THREAD}`,
      removeThread: `contact/${REMOVE_CURRENT_CONTACT_THREAD}`,
      getContactThreadCloseStatusReasons: `contact/${GET_CONTACT_THREAD_CLOSE_STATUS_REASONS}`,
      closeContactThreadFunnelProcess: `contact/${CLOSE_CONTACT_THREAD_FUNNEL_PROCESS}`,
      getThreadTasks: `contact/${GET_THREAD_TASKS}`,
    }),
    ...mapMutations({
      changeActiveThread: 'contact/changeActiveThread',
    }),

    scrollContainerToTop() {
      document.getElementById('contact-container').scrollTo({ top: this.$refs[`${this.uuid}-threadItem`].$el.offsetTop - 145, behavior: 'smooth' })
    },

    protectSetPrimaryThread(thread) {
      return this.checkRequiredPermissions([
        this.$roles.CONTACT_THREAD_EDIT_ALL,
        {
          role: this.$roles.CONTACT_THREAD_EDIT_ASSIGNED, data: { selectedThread: thread }, userId: this.user.id, field: 'assignedUsers',
        },
        {
          role: this.$roles.CONTACT_THREAD_EDIT_SELF_ASSIGNED, data: { selectedThread: thread }, userId: this.user.id, field: 'createdBy',
        },
      ])
    },
    protectEditThread(thread) {
      return this.checkRequiredPermissions([
        this.$roles.CONTACT_THREAD_EDIT_ALL,
        {
          role: this.$roles.CONTACT_THREAD_EDIT_ASSIGNED, data: { selectedThread: thread }, userId: this.user.id, field: 'assignedUsers',
        },
        {
          role: this.$roles.CONTACT_THREAD_EDIT_SELF_ASSIGNED, data: { selectedThread: thread }, userId: this.user.id, field: 'createdBy',
        },
      ])
    },

    async loadThreadList() {
      this.loading = true

      try {
        this.threadList = await this.getThreadList()
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }

      this.loading = false
    },
    async loadThreadCloseStatusReasons() {
      this.loading = true

      try {
        this.threadCloseStatusReasons = await this.getContactThreadCloseStatusReasons()
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }

      this.loading = false
    },
    progressCols(threadLength) {
      switch (threadLength) {
        case 1:
          return 12
        case 2:
          return 6
        case 3:
          return 4
        default:
          return 3
      }
    },
    progressColor(progress) {
      if (progress === 100) return 'success'
      if (progress > 0 && progress < 100) return 'warning'

      return 'secondary'
    },
    async switchThreadPrimaryStatus(thread) {
      try {
        await this.switchThreadPrimary({ id: this.contact.id, thread })

        const payload = { ...thread }

        payload.isPrimary = !thread.isPrimary

        EventBus.$emit(eChangeThreadPrimaryStatus, {
          thread: payload,
        })
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async closeThreadFunnelProcess() {
      const contactThreadStatusReason = this.getObjectId(this.closeNote.reason)
      const reason = this.threadCloseStatusReasons.find(r => r.id === contactThreadStatusReason)

      try {
        const { payload } = await this.closeContactThreadFunnelProcess({
          thread: this.closeNoteThread.thread,
          closeNote: this.closeNote,
          reason,
        })

        EventBus.$emit(eChangeFunnelProcessStatus, {
          thread: this.closeNoteThread.thread,
          funnelProcess: { ...payload, contactThreadStatusReason: reason },
        })

        this.showCloseNoteModal = false
        this.closeNoteThread = null

        this.closeNote.reason = this.emptyCloseNote.reason
        this.closeNote.note = this.emptyCloseNote.note
        this.closeNote.status = this.emptyCloseNote.status
        this.$forceUpdate()
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)

        this.closeNote.reason = this.emptyCloseNote.reason
        this.closeNote.note = this.emptyCloseNote.note
        this.closeNote.status = this.emptyCloseNote.status
        this.$forceUpdate()
      }
    },
    copy(txt) {
      this.$copyText(txt, this.$refs.contact_container).then(() => {
        this.showToast('primary', this.$i18n.t('CopiedToClipboard'))
      }, e => {
        this.showToast('danger', e)
      })
    },
    getThreadStatus(status) {
      switch (status) {
        case 'CLOSED_POSITIVE':
          return {
            status: 'COMPLETED',
            text: 'text-primary',
            variant: 'primary',
          }
        case 'CLOSED_NEGATIVE':
          return {
            status: 'REJECTED',
            text: 'text-danger',
            variant: 'danger',
          }
        default:
          return null
      }
    },
    async removeThreadItem(threadItem) {
      await this.removeThread(threadItem)

      EventBus.$emit(eRemoveThread, { thread: threadItem })
    },
    handleChangeFunnelProcess({ thread, funnelProcess }) {
      const t = this.threadList.find(({ id }) => id === thread.id)

      t.contactThreadFunnelProcessSelected = funnelProcess
    },
    handleChangeFunnelProcessStatus({ thread, funnelProcess }) {
      const t = this.threadList.find(({ id, contactThreadFunnelProcessSelected }) => id === thread.id
          && contactThreadFunnelProcessSelected.id === funnelProcess.id)

      if (t) {
        t.contactThreadFunnelProcessSelected.status = funnelProcess.status
        t.contactThreadFunnelProcessSelected.statusNote = funnelProcess.statusNote
        t.contactThreadFunnelProcessSelected.contactThreadStatusReason = funnelProcess.contactThreadStatusReason
      }
    },
    async handleNewThread({ thread }) {
      const newThread = await this.getThread(thread.id)

      if (newThread) this.threadList.push(newThread)
    },
    async handleEditThread({ thread }) {
      const newThread = await this.getThread(thread.id)

      if (newThread) {
        const i = this.threadList.findIndex(({ id }) => id === newThread.id)

        if (i > -1) {
          this.$set(this.threadList, i, newThread)
          // this.threadList[i] = newThread
          this.$forceUpdate()
        }
      }
    },
    handleRemoveThread({ thread }) {
      const i = this.threadList.findIndex(({ id }) => id === thread.id)

      if (i) {
        this.threadList.splice(i, 1)
        this.$forceUpdate()

        if (thread.id === this.thread.id) this.changeActiveThread(this.threadList[0])
      }
    },
    handleChangeThreadPrimaryStatus({ thread }) {
      const newPrimaryThread = this.threadList.find(({ id }) => id === thread.id)
      const oldPrimaryThread = this.threadList.find(({ isPrimary }) => isPrimary)

      if (newPrimaryThread) newPrimaryThread.isPrimary = thread.isPrimary
      if (oldPrimaryThread) oldPrimaryThread.isPrimary = false

      this.$forceUpdate()
    },
    async handleNewThreadTask({ threadId }) {
      const el = this.threadList.find(({ id }) => id === threadId)

      if (el) {
        const tasks = await this.getThreadTasks(el.id)
        el.contactThreadTasksOpened = tasks
        this.$forceUpdate()
      }
    },
    mCloseNoteModal(type, thread, i) {
      this.closeNote.status = type
      this.showCloseNoteModal = true
      this.closeNoteThread = { thread, index: i }
    },
    mAddThread() {
      this.$store.commit(`modal/${OPEN_MODAL}`, {
        modalType: 'thread',
        modalTitle: this.$i18n.t('AddThread'),
        okButtonTitle: this.$i18n.t('Save'),
        contactId: this.contact.id,
        modalClass: 'thread-modal',
        size: 'lg',
      })
    },
    mEditThread(thread) {
      this.$store.commit(`modal/${OPEN_MODAL}`, {
        modalType: 'thread',
        size: 'lg',
        modalTitle: this.$i18n.t('EditThread'),
        okButtonTitle: this.$i18n.t('Save'),
        editedData: thread,
        contactId: this.contact.id,
        modalClass: 'thread-modal',
        isAssignedContactsEdit: false,
      })
    },
  },
}
</script>

<style lang="scss">
.popover-tasks {
  min-width: 280px;
  max-width: 370px !important;

  .popover-body {
    padding: 0.65rem 0 !important;
  }
}
</style>
